import React, { useEffect, useState } from "react";
import classes from "./OccupancyCalendar.module.css";
import closeIcon from "../../../../images/xmarkicon.svg";
import calendaricon from "../../../../images/calendaricon.svg";
import { getOccupancyData } from "../../../../utils/ApiFunctions";
import Loader from "../../../reusable/Loader";
import { Drawer } from "@mui/material";

const OccupancyCalendar = ({ eventId, eventDate, eventDay, open, onClose }) => {
    const courts = ["Gold 1", "Gold 2", "007", "747", "Double", "Add1", "Add2"];
    const [loading, setLoading] = useState(true);
    const [occupancyData, setOccupancyData] = useState([]);


    const fetchOccupancyData = async () => {
        if (open) {
            setLoading(true);
            const resp = await getOccupancyData(eventId);
            const data = resp?.data?.occupancyData;
            setOccupancyData(data);
            setLoading(false);
        } else {
            setOccupancyData([]);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchOccupancyData();
    }, [open])
    // Function to calculate rowspan for merging cells (excluding "Available")
    const calculateRowSpans = (data, court) => {
        const rowSpans = Array(data.length).fill(1);
        for (let i = 0; i < data.length; i++) {
            if (rowSpans[i] === 0 || data[i].slots[court] === "Available") continue; // Skip "Available" or already-merged cells
            let count = 1;
            for (let j = i + 1; j < data.length; j++) {
                if (data[j].slots[court] === data[i].slots[court] && data[i].slots[court] !== "Available") {
                    count++;
                    rowSpans[j] = 0; // Mark as merged
                } else {
                    break;
                }
            }
            rowSpans[i] = count;
        }
        return rowSpans;
    };

    // Pre-calculate rowSpans for each court
    const rowSpansByCourt = {};
    if (occupancyData && occupancyData.length > 0) {
        courts.forEach((court) => {
            rowSpansByCourt[court] = calculateRowSpans(occupancyData, court);
        });
    }


    if (!open) return null;
    return (
        <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ style: { width: "80%" } }}>
            <div className={classes.calendar_container}>
                <div className={classes.container}>
                    {(
                        <div className={classes.calendar_title}>
                            <div>
                                <h1>Occupancy Calendar</h1>
                                <div className={classes.info}><img src={calendaricon} alt="calendarIcon" /> <span>{eventDate}, {eventDay}</span></div>
                            </div>
                            <a onClick={onClose}>
                                <img src={closeIcon} alt="closeIcon" />
                            </a>
                        </div>
                    )}
                    {loading && <Loader />}
                    {!loading && occupancyData && occupancyData.length > 0 && (
                        <div className={classes.table_container}>
                            <table>
                                <thead>
                                    <tr>
                                        <th width="138px">Time slots</th>
                                        {courts.map((court) => (
                                            <th className={classes.text_red} key={court}>{court}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className={classes.table_body}>
                                    {occupancyData.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className={classes.timeslot}>
                                                <div className={classes.timeslot_cell}>
                                                    {row['timeSlot']}
                                                </div>
                                            </td>
                                            {courts.map((court) => {
                                                const rowSpan = rowSpansByCourt[court][rowIndex];
                                                if (rowSpan === 0) return null; // Skip merged cells
                                                return (
                                                    <td
                                                        key={court}
                                                        rowSpan={rowSpan}
                                                        className={`${classes.table_cell} ${row.slots[court] !== 'Available' ? classes.not_available : classes.available}`}
                                                    >
                                                        <div className={classes.occupancy_status}>
                                                            <span className={classes.status_indicator}></span>
                                                            <span>{row.slots[court]}</span>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </Drawer>
    )

}
export default OccupancyCalendar;