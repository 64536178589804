import "./customvariables.scss";
import classes from "./MyCalendar.module.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";
import { useNavigate } from "react-router-dom";

import CustomHeader from "./CustomHeader";
import { useEffect, useState } from "react";
import { getAllEvents } from "../../../utils/ApiFunctions";
import Header from "../Header";
import { useDispatch } from "react-redux";
import { eventActions, linkActions } from "../../store";
import Loader from "../../reusable/Loader";



const localizer = momentLocalizer(moment);

// const myEventsList = [
//   {
//     id: 1,
//     title: "Company anniversary",
//     start: new Date(2024, 6, 7, 14, 30), // year, month (0-based), day, hour, minute
//     end: new Date(2024, 6, 7, 17, 0),
//     assignedTo: "John Doe",
//     employees: 51,
//     format: "Party",
//     court: "Gold 1",
//     payment: "Online",
//     lead: "-",
//   },
//   {
//     id: 2,
//     title: "Sports Event",
//     start: new Date(2024, 6, 7, 15, 0),
//     end: new Date(2024, 6, 7, 18, 0),
//     assignedTo: "Marvin McKinney",
//     employees: 67,
//     format: "Global",
//     court: "Gold 2",
//     payment: "Online",
//     lead: "-",
//   },
//   {
//     id: 3,
//     title: "Bring kids to office event",
//     start: new Date(2024, 5, 30, 13, 45),
//     end: new Date(2024, 5, 30, 16, 0),
//     assignedTo: "Eleanor Pena",
//     employees: 71,
//     format: "Local",
//     court: "007",
//     payment: "Offline",
//     lead: "-",
//   },
//   {
//     id: 4,
//     title: "Family reunion event",
//     start: new Date(2024, 6, 7, 14, 0),
//     end: new Date(2024, 6, 7, 16, 0),
//     assignedTo: "Theresa Webb",
//     employees: 89,
//     format: "Local",
//     court: "Double",
//     payment: "Offline",
//     lead: "-",
//   },
// ];

const MyCalendar = (props) => {
  const [events,setEvents]=useState([]);
  const [loading,setLoading]=useState(true);
  const getEvents = async () => {
    const resp = await getAllEvents();
    if (resp) {
      const temp = resp.data.events?.map((event) => {
        // Combine date and time to create valid Date objects
        const eventDate = moment(event.date); // Assuming event.date is in ISO format
        const fromTime = moment(event.fromTime, "HH:mm");
        const toTime = moment(event.toTime, "HH:mm");
  
        const start = eventDate
          .clone()
          .hour(fromTime.hour())
          .minute(fromTime.minute())
          .toDate();
  
        const end = eventDate
          .clone()
          .hour(toTime.hour())
          .minute(toTime.minute())
          .toDate();
  
        return {
          _id: event._id,
          title: `${event.eventName} (${event.fromTime} - ${event.toTime})`,
          start,
          end,
          assignedTo: event.contactPerson,
          employees: event.noOfParticipants,
          format: event.eventFormat,
          court: event.court.map((c) => `${c.name}`).join(", "),
          paymentStatus: event.paymentStatus,
        };
      });
  
      setEvents(temp);
      setLoading(false);
    }
    setLoading(false);
  };
  
  const dispatch=useDispatch();
  useEffect(()=>{
    getEvents();
    dispatch(linkActions.setTo('/calendar'));
  },[]);

  const navigate = useNavigate();
  
  const handleSelectEvent = (event) => {
    navigate(`/viewevent/${event._id}`);
  };
  return (
    <>
    <Header activeTab={"calendar"}/>
    {loading&&<Loader/>}
    {!loading&&
    <div className={classes.calcontainer}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        views={["month"]}
        components={{
          toolbar: CustomToolbar,
          month: {
            header: CustomHeader
          }
        }}
        onSelectEvent={handleSelectEvent}
      />
    </div>}
    </>
  );
};

export default MyCalendar;
