import React, { useEffect, useState } from "react";
import CustomEventList from "../../reusable/CustomEventList";
import moment from "moment";
import { getAllEvents, getEvents } from "../../../utils/ApiFunctions";
import { useDispatch, useSelector } from "react-redux";
import { eventActions, linkActions } from "../../store";
import Loader from "../../reusable/Loader";
import NoData from "../../reusable/NoData";

function AllEvents(props) {
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const getEvent = async () => {
    const resp = await getEvents();
    if (resp) {
      setTotalPages(resp.totalPages);

      const temp = resp.data.events?.map((event) => ({
        _id: event._id,
        name: event.eventName,
        date: moment(event.date).format("YYYY-MM-DD"),
        // time: `${moment(event.fromTime).format("HH:mm")}-${moment(
        //   event.toTime
        // ).format("HH:mm")}`,
        time : `${event.fromTime} - ${event.toTime}`,
        assignedTo: event.contactPerson,
        employees: event.noOfParticipants,
        format: event.eventFormat,
        court: event.court.map((c) => `${c.name}`).join(", "),
        status: event.status,
        paymentStatus: event.paymentStatus,
      }));
      setEvents(temp);

      setLoading(false);
    }
    setLoading(false);
  };

  const getTotalEvents = async () => {
    const resp = await getAllEvents();
    if (resp) {
      const temp = resp.data.events?.map((event) => ({
        Name: event.eventName,
        Date: moment(event.date).format("YYYY-MM-DD"),
        Time: `${moment(event.fromTime).format("HH:mm")}-${moment(
          event.toTime
        ).format("HH:mm")}`,
        ContactPerson: event.contactPerson,
        Employees: event.noOfParticipants,
        Format: event.eventFormat,
        Court: event.court.map((c) => `${c.name}`).join(", "),
        PaymentStatus: event.paymentStatus,
        Amount: event.amount,
        Gender: event.gender,
        ageGroup: event.ageGroup.map((a) => `${a}`).join(", "),
        food: event.food.map((f) => `${f}`).join(", "),
        techManager: event.techManager,
        eventManager: event.eventManager,
        paymentType: event.payment
      }));
      dispatch(eventActions.setAllEvents(temp));
    }
  };

  useEffect(() => {
    getEvent();
  }, [page, deleted]);

  // const to = useSelector((state) => state.link.to);

  useEffect(() => {
    getTotalEvents();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && !events[0] &&
        <NoData title={"No Events"} subtitle={"Create event to be added to the list"} />
      }
      {!loading && events[0] && (
        <CustomEventList
          responsive={'tableTabletAdminEvents'}
          events={events}
          page={page}
          setPage={setPage}
          eventstate={"all"}
          totalPages={totalPages}
          setDeleted={setDeleted}
        />
      )}
    </>
  );
}

export default AllEvents;
